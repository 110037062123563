<template>
  <b-row class="match-height">
      <b-col
        xl="2"
        md="4"
        sm="6"
        v-for="item in type.type" :key="item.id"
      >
        <b-card class="text-center" style="cursor: pointer" @click.prevent="getDataByType(item.id)">
          <b-avatar
            class="mb-1"
            variant="light-info"
            size="45"
          >
            <feather-icon
              size="21"
              icon="SettingsIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ numFormat(item.count) }}
            </h2>
            <span>{{ item.name }}</span>
          </div>
        </b-card>
      </b-col>
      <!-- <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="DatabaseIcon"
          :statistic="ganti_oli"
          statistic-title="Ganti Oli"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="CpuIcon"
          :statistic="kampas_rem"
          statistic-title="Ganti Kampas Rem"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="DiscIcon"
          :statistic="ganti_ban"
          statistic-title="Ganti Ban"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="BatteryChargingIcon"
          :statistic="ganti_aki"
          statistic-title="Ganti Aki"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="TruckIcon"
          :statistic="own_risk"
          statistic-title="Own Risk"
        />
      </b-col> -->
      <b-col
        xl="12"
        md="12"
        sm="12"
      >
        <b-row>
          <b-col
            xl="12"
            md="12"
            sm="12"
          >
            <b-card>
              <!-- <b-table
                responsive
                :items="items"
                :fields="fields"
                class="mb-0"
              >
              </b-table>
              <b-pagination
                v-if="isPagination"
                v-model="currentPage"
                :total-rows="recordsTotal"
                :per-page="pageSize"
                aria-controls="my-table"
              /> -->
              <base-table-maintenance
                ref="basetablemaintenance"
                :fields="fields"
                :dataurl="dataurl"
                :baseroute="baseroute"
                :title="title"
                :is-edit="false"
                :is-delete="false"
                :is-add="false"
                :pageSize="5"
                :typeId="id"
                v-if="idType"
                :isPagination="false"
              >
              <!-- <template #cell(_)="data">
                <b-button
                  v-if="data.id"
                  size="sm"
                  variant="primary"
                  class="mr-1 btn-icon"
                  @click="view(data)"
                >
                  <feather-icon icon="FolderIcon" />
                </b-button>
              </template> -->
              </base-table-maintenance>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BTable, BPagination, BAvatar } from 'bootstrap-vue'
import BaseTableMaintenance from '@/views/base/BaseTableMaintenance.vue'

export default {
  components: {
    BRow, BCol, BTable,
    BaseTableMaintenance,
    BCard,
    BAvatar
  },
  data () {
    return {
      dataurl:null,
      baseroute:null,
      title:null,
      idType:false,
      items:[],
      // fields: [
      //   'Judul',
      //   'Varian',
      //   'Interval',
      //   'Tanggal',
      //   'Status'
      // ],
      fields: null,
      showFilter : false,
      params: {
        id: null
      },
      type: [{
        type: null
      }],
      isDetail : true,
      id: null,
      data:null,
      // isPagination: true,
      // currentPage: 1,
      // sortBy: '',
      // sortDirection: true,
      // recordsTotal: 0,
      // search: '',
      // loading:false,
      // pageSize:{
      //   type:Number,
      //   default:10
      // }
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
      this.params['typeId'] = this.id
      this.$http.get('dashboard/maintenance', {params:this.params}).then(res=>{
        this.type = res.data
        this.data = res.data.detail.data
        console.log(this.data);
        this.dataurl = "dashboard/maintenance"
        this.baseroute = "semua-perawatan"
        this.fields = [
          { key: 'name', label: 'Judul', sortable: true },
          { key: 'variant', label: 'Varian', sortable: true },
          { key: 'interval', label: 'Interval', sortable: true },
          { key: 'date', label: 'Tanggal', sortable: true },
          { key: 'status', label: 'Status', sortable: true }
        ]

        if(res.data.detail.recordsTotal > 0){
          this.title = res.data.detail.title
        }
        else{
          this.title = null
        }

        this.idType = false
        this.$nextTick().then(() => {
          this.idType = true
        })
      })
    },
    view(data){
      // console.log(data);
      this.$router.push({ name: 'semua-perawatan',params : { uuid: data.data.id} })
    },
    getDataByType(id){
      this.id = id
      this.getData()
    },
    numFormat(number){
      var type = ''
      if(number > 1000000000000){
        number = (number/1000000000000).toFixed(3)
        type = ' T'
      }else if(number > 1000000000){
        number = (number/1000000000).toFixed(2)
        type = ' M'
      }else if(number > 1000000){
        number = (number/1000000).toFixed(2)
        type = ' Jt'
      }else if(number > 1000){
        number = (number/1000).toFixed(2)
        type = ' Rb'
      }

      if(type != '' && number.includes(".")){
        number = number.split('.')
        if(number[1] == 0){
          number = number[0]
        }
      }
      return number+type
    }
  }
}
</script>

<style lang="scss" scoped>

</style>